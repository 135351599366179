<template>
  <div class="drag-panel">
    <div class="drag-panel-header">
      <span class="header-title">
        <span class="name">Node Properties&nbsp;</span>
      </span>
    </div>
    <div class="drag-panel-content">

        <b-row class="node-property-workarea">
<!--
          <b-col>
            <div>
-->
           <b-container fluid class="node-property">
             <div v-if="displayNodeProperties">
              <b-card no-body class="mt-2 mb-2 mx-0">
                <template #header>
                  <h6 id="node-properties-header" class="mb-0"></h6>
                </template>
                  <b-list-group flush>
                    <b-list-group-item class="pt-1 pb-2 px-0" id="node-properties-list-group"></b-list-group-item>
                  </b-list-group>
              </b-card>
            </div>
          </b-container>
<!--
            </div>
          </b-col>
-->
        </b-row>

    </div>
    <div class="drag-panel-footer"></div>
  </div>
</template>

<script>
  import $ from 'jquery'
  import EventBus from './eventbus'

  export default {
    name: 'NodePropertyPanel',
    components: {
    },
    props: {
    },
    data() {
      return {
        displayNodeProperties: true,
      }
    },
    created() {
    },
    mounted() {
      EventBus.$on('setNodeProperty', data => {
        //console.log("setNodeProperty: " + data)
        this.updateNodeProperty(data)
      })
    },
    methods: {
      updateNodeProperty(data) {
        //console.log("updateNodeProperty: " + JSON.stringify(data))
        //let title = "Node properties:" + "&nbsp;" + data.title
        let title = data.title
        $('#node-properties-header').html(title)
        $('#node-properties-list-group').empty()
        //console.log(data.rows)
        $('#node-properties-list-group').append(data.rows)
      }
    }
  }
</script>

<style scoped>
  .drag-panel {
    background-color: olive;
    height: 100%;
    max-height: 100%;

    position: fixed;
    top: 0;
    left: 0;
    right: 0;
  }

  .drag-panel-header {
    position: fixed;
    top: 0;
    left: 0;
    right: 0;
    height: 40px;
    background-color: lightgray;
  }

  .header-title {
    position: fixed;
    top: 5px;
    left: 5px;
    //right: 0;
    height: 40px;
    font-size: 20px;
    font-weight: 500; /* 400 is normal; 700 is bold */
  }

/*
  .header-title .name { float:left }
  .header-title .version { float:right }
*/
  span + span {
    margin-left: 5px;
  }

  .drag-panel-content {
    position: fixed;
    top: 35px;
    bottom: 30px;
    left: 0;
    right: 0;
    background-color: white;
    border: 1px solid black;
    //overflow: auto;
    //-webkit-overflow-scrolling: touch;
  }

  .drag-panel-footer {
    position: fixed;
    bottom: 0;
    left: 0;
    right: 0;
    height: 30px;
    background-color: lightgray;
  }

  p {
    font-size: 12px;
  }

  .graph-model {
    width: 100%;
    height: 100%;
    background-color: whitesmoke;
  }
  .graph-model-header {
    height: 60px;
  }
  .node-property-workarea {
    //height: 75%;
    position: fixed;
    //top: 80px;
    top: 35px;
    bottom: 250px;
    left: 0;
    right: 0;
    margin-left: 2px;
    margin-right: 2px;
    //background-color: lightblue;
    //border: 1px solid black;
  }
  .graph-model-footer {
    //height: 100px;
    position: fixed;
    bottom: 0;
    left: 0;
    right: 0;
    height: 175px;
  }
</style>
