<template>
  <div class="drag-panel">
    <div class="drag-panel-header">
      <span class="header-title">Publications</span>
    </div>
    <div class="drag-panel-content">

  <b-input-group class="mt-3 px-2">
    <b-input-group-append>
      <b-dropdown id="select-box" text="Sort" class="" dropleft>

        <b-list-group horizontal="md" class="mx-1 row-sort-order">
          <b-list-group-item>
            <b-button-group>
              <b-button squared
                id="sort-down-2"
                v-on:click="priorityChange($event, 'down')">
                  <font-awesome-icon icon="angle-down" />
              </b-button>
              <b-button squared
                id="sort-up-2"
                class="ml-3"
                v-on:click="priorityChange($event, 'up')">
                  <font-awesome-icon icon="angle-up" />
              </b-button>
            </b-button-group>
          </b-list-group-item>
          <b-list-group-item>
            <b-form-checkbox
              id="checkbox-1"
              v-model="status"
              name="checkbox-1"
              value="accepted"
              unchecked-value="not_accepted"
              class="sort-checkbox mt-2"
            >
              <span class="mx-1">Author</span>
            </b-form-checkbox>
          </b-list-group-item>
          <b-list-group-item>
            <b-button-group>
              <b-button squared
                id="author-sort-order"
                class="sortflip ascending"
                v-on:click="sortFlip($event)">
                <font-awesome-icon :icon="authorArrow" />
              </b-button>
            </b-button-group>
          </b-list-group-item>
        </b-list-group>

        <b-list-group horizontal="md" class="mx-1 row-sort-order">
          <b-list-group-item>
            <b-button-group>
              <b-button squared
                id="sort-down-3"
                v-on:click="priorityChange($event, 'down')">
                  <font-awesome-icon icon="angle-down" />
              </b-button>
              <b-button squared
                id="sort-up-3"
                class="ml-3"
                v-on:click="priorityChange($event, 'up')">
                  <font-awesome-icon icon="angle-up" />
              </b-button>
            </b-button-group>
          </b-list-group-item>
          <b-list-group-item>
            <b-form-checkbox
              id="checkbox-2"
              v-model="status2"
              name="checkbox-2"
              value="accepted"
              unchecked-value="not_accepted"
              class="sort-checkbox mt-2"
            >
              <span class="mx-1">Title</span>
            </b-form-checkbox>
          </b-list-group-item>
          <b-list-group-item>
            <b-button-group>
              <b-button squared
                id="title-sort-order"
                class="sortflip ascending"
                v-on:click="sortFlip($event)">
                <font-awesome-icon :icon="titleArrow" />
              </b-button>
            </b-button-group>
          </b-list-group-item>
        </b-list-group>

        <b-list-group horizontal="md" class="mx-1 row-sort-order">
          <b-list-group-item>
            <b-button-group>
              <b-button squared
                id="sort-down-4"
                v-on:click="priorityChange($event, 'down')">
                  <font-awesome-icon icon="angle-down" />
              </b-button>
              <b-button squared
                id="sort-up-4"
                class="ml-3"
                v-on:click="priorityChange($event, 'up')">
                  <font-awesome-icon icon="angle-up" />
              </b-button>
            </b-button-group>
          </b-list-group-item>
          <b-list-group-item>
            <b-form-checkbox
              id="checkbox-3"
              v-model="status3"
              name="checkbox-3"
              value="accepted"
              unchecked-value="not_accepted"
              class="sort-checkbox mt-2"
            >
              <span class="mx-1">Journal Name</span>
            </b-form-checkbox>
          </b-list-group-item>
          <b-list-group-item>
            <b-button-group>
              <b-button squared
                id="journal-name-sort-order"
                class="sortflip ascending"
                v-on:click="sortFlip($event)">
                <font-awesome-icon :icon="journalNameArrow" />
              </b-button>
            </b-button-group>
          </b-list-group-item>
        </b-list-group>

        <b-list-group horizontal="md" class="row-sort-order mx-1">
          <b-list-group-item>
            <b-button-group>
              <b-button squared
                id="sort-down-5"
                v-on:click="priorityChange($event, 'down')">
                  <font-awesome-icon icon="angle-down" />
              </b-button>
              <b-button squared
                id="sort-up-5"
                class="ml-3"
                v-on:click="priorityChange($event, 'up')">
                  <font-awesome-icon icon="angle-up" />
              </b-button>
            </b-button-group>
          </b-list-group-item>
          <b-list-group-item>
            <b-form-checkbox
              id="checkbox-4"
              v-model="status4"
              name="checkbox-4"
              value="accepted"
              unchecked-value="not_accepted"
              class="sort-checkbox mt-2"
            >
              <span class="mx-1">Year</span>
            </b-form-checkbox>
          </b-list-group-item>
          <b-list-group-item>
            <b-button-group>
              <b-button squared
                id="year-sort-order"
                class="sortflip ascending"
                v-on:click="sortFlip($event)">
                <font-awesome-icon :icon="yearArrow" />
              </b-button>
            </b-button-group>
          </b-list-group-item>
        </b-list-group>

      </b-dropdown>
    </b-input-group-append>

    <b-form-input
      id="searchInput"
      v-model="searchInput"
      v-on:keyup.enter="searchFilter">
    </b-form-input>

    <b-input-group-append>
      <b-button
        variant="outline-secondary"
        v-on:click="searchFilter">
        <font-awesome-icon icon="search" />
      </b-button>
    </b-input-group-append>
  </b-input-group>

<!--
      <small class="mt-3">{{ nodeTitle }}</small>
-->

      <!--  -->
      <b-form-input
        v-model="nodeTitle"
        placeholder=""
        :disabled=true
        class="mt-2">
      </b-form-input>

      <!--  -->
<!--
      <small class="mt-3">Current Page: {{ currentPage }}</small>
-->

      <!-- top pagination -->
      <!-- class="pagination" -->
      <b-pagination
        v-model="currentPage"
        :total-rows="rows"
        :per-page="perPage"
        aria-controls="my-table"
        size="sm"
        class="pagination mt-1 ml-1">
      </b-pagination>

      <!--  -->
      <div class="publication-panel">

<!-- :fields="fields" -->

        <b-table
          id="my-table"
          :items="items"
          :fields="fields"
          :per-page="perPage"
          :current-page="currentPage"
          :small="small"
          striped
          hover>
          <template #cell(article)="data">
            <span v-html="data.value"></span>
          </template>
        </b-table>

      </div>

      <div class="publication-panel-footer">
        <b-pagination
          v-model="currentPage"
          :total-rows="rows"
          :per-page="perPage"
          aria-controls="my-table"
          size="sm"
          class="pagination">
        </b-pagination>
      </div>

    </div>
    <div class="drag-panel-footer"></div>
  </div>
</template>

<script>
  import $ from 'jquery'
  import EventBus from './eventbus'

  export default {
    name: 'PublicationPanel',
    components: {
    },
    data() {
      return {
        // change status to 'name of checkbox'_status such as author_status
        status: 'not_accepted',
        status2: 'accepted',
        status3: 'not_accepted',
        status4: 'not_accepted',
        //sortOrder: [{"Curated": "desc"}],
        sortOrder: [],
        searchInput: '',
        perPage: 10,
        filter: null,
        currentPage: 1,
        small: true,
        nodeTitle: '',
        authorArrow: 'arrow-down',
        titleArrow: 'arrow-down',
        journalNameArrow: 'arrow-down',
        yearArrow: 'arrow-down',
        fields: [{
          key: 'article',
          label: 'Article',
          thStyle: {
            display: 'none'
          }
        }],
        items: [
        ],
        pubmed: [
        ]
      }
    },
    computed: {
      rows() {
        return this.items.length
      }
    },
    mounted() {
      var self = this
      EventBus.$on('setPublication', data => {
        //console.log(JSON.stringify(data.result) + ", " + data.name)
        this.nodeTitle = data.name

        this.items = data.result
        this.pubmed = data.result
        //console.log(this.items)

        let pubmed_list = this.items
        //console.log(pubmed_list)
        let sort_order = this.getSortOrder()
        this.getPubmedList(pubmed_list, sort_order)
      })
    },
    methods: {
      updateSortArrow(event, target) {
        let id = event.currentTarget.id
        //console.log(id)

        if(id === 'author-sort-order') {
          if(this.authorArrow === 'arrow-down') {
            this.authorArrow = 'arrow-up'
          }
          else if(this.authorArrow === 'arrow-up') {
            this.authorArrow = 'arrow-down'
          }
        }
        else if(id === 'title-sort-order') {
          if(this.titleArrow === 'arrow-down') {
            this.titleArrow = 'arrow-up'
          }
          else if(this.titleArrow === 'arrow-up') {
            this.titleArrow = 'arrow-down'
          }
        }
        else if(id === 'journal-name-sort-order') {
          if(this.journalNameArrow === 'arrow-down') {
            this.journalNameArrow = 'arrow-up'
          }
          else if(this.journalNameArrow === 'arrow-up') {
            this.journalNameArrow = 'arrow-down'
          }
        }
        else if(id === 'year-sort-order') {
          if(this.yearArrow === 'arrow-down') {
            this.yearArrow = 'arrow-up'
          }
          else if(this.yearArrow === 'arrow-up') {
            this.yearArrow = 'arrow-down'
          }
        }
      },
      // ascending up
      // descending down
      showAscendingIcon(event, target) {
        var selector = $(target)
        selector.removeClass('descending')
        selector.addClass('ascending')

        selector.removeClass('fa-arrow-down')
        selector.addClass('fa-arrow-up')

        //console.log(selector)

        //selector.innerHTML = '<font-awesome-icon icon="arrow-up" />'
        this.updateSortArrow(event, target)
      },
      showDescendingIcon(event, target) {
        var selector = $(target)
        selector.removeClass('ascending')
        selector.addClass('descending')

        selector.removeClass('fa-arrow-up')
        selector.addClass('fa-arrow-down')

        //console.log(selector)
        //selector.innerHTML = '<font-awesome-icon icon="arrow-down" />'
        this.updateSortArrow(event, target)
      },
      sortFlip(event) {
        //var selected_field = $(".search-panel .dropdown-menu .sortflip")
        //console.log(selected_field)
        //

        //console.log(event.currentTarget)

        let selected_field = event.currentTarget.classList
        //console.log(selected_field)

        if(selected_field.contains('ascending')) {
          //console.log('ascending')
          this.showDescendingIcon(event, event.currentTarget)
          //selected_field.attr('direction', 'desc')
        }
        else if(selected_field.contains('descending')) {
          //console.log('descending')
          this.showAscendingIcon(event, event.currentTarget)
          //selected_field.attr('direction', 'asc')
        }
      },
      getSortDirection(target) {
        //console.log('\n')
        //
        //let sortflip = $('#author-sort-order')
        let sortflip = target
        //console.log(sortflip)

        var sortflip_class = sortflip.attr('class')
        //console.log(sortflip_class)

        var direction = ""

        // get sort up or down for this child
        if(sortflip_class.indexOf("ascending") >= 0) {
          //console.log("sortflip: up");
          direction = "asc"
        }
        else if(sortflip_class.indexOf("descending") >= 0) {
          //console.log("sortflip: down");
          direction = "desc"
        }

        //
        //console.log('\n')

        return direction
      },
      updateItems(result) {
        var self = this
        this.items = []
        var pubmed_counter = 0;

        //console.log(result)

        if(result != null) {
          //console.log("result: " + result)

          result.forEach(data => {
            //console.log(data)
            let pubmed_id = data.pubmed_id
            let url = "https://www.ncbi.nlm.nih.gov/pubmed/" + pubmed_id

            let first_author = data.first_author
            if(first_author) {
              if(first_author === "NULL") {
                first_author = 'No authors listed.'
              }
              else {
                if(data.author_count > 1) {
                  first_author = first_author + ' et al.'
                }
                else {
                  first_author = first_author + '.'
                }
              }
            }
            else {
              //console.log("no first author: " + pubmed_id)
              first_author = 'No authors listed.'
            }

            // title
            let title = data.title
            if(title) {
              if(title === "NULL") {
                //console.warn("title is NULL")
                title = 'No title.'
              }
              else {
                title = ' ' + title;
              }
            }
            else {
              // book title
              let book_title = data.book_title
              if(book_title) {
                //console.log("book title: " + book_title)
                //let title = data.book_title
                let publisher_location = data.publisher_location
                let publisher_name = data.publisher_name
                title = ' ' + book_title + '. ' + publisher_location + ': ' + publisher_name + '; ';
                //console.log("setting title to book title")
              }
            }

            let journal = data.journal_name;
            if(journal) {
              if(journal === "NULL") {
                journal = 'No journal.'
              }
              else {
                journal = ' ' + journal;
                //console.log(journal)
              }
            }
            else {
              journal = ''
            }

            let volume = data.volume;
            if(volume) {
              if(volume === "NULL") {
                volume = 'No volume.'
              }
              else {
                volume = ' ' + volume;
              }
            }
            else {
              volume = ''
            }

            let issue = data.issue;
            if(issue) {
              if(issue === "NULL") {
                issue = 'No issue.'
              }
              else {
                issue = '(' + issue + ')';
              }
            }
            else {
              issue = ''
            }

            let pages = data.pages;
            if(pages) {
              if(pages === "NULL") {
                pages = 'No pages.'
              }
              else {
                pages = ': ' + pages;
              }
            }
            else {
              pages = ''
            }

            let year = data.year;
            if(year) {
              if(year === "NULL") {
                year = 'No year.'
              }
              else {
                year = ' (' + year + ')';
              }
            }
            else {
              year = ''
            }

            let publication_class = "";
            let curated = data.curated;
              //console.log("curated: " + pubmed_id + ", " + curated);

            if(curated == 1) {
              publication_class = "curated-link";
            }
            else if(curated == 0){
              publication_class = "uncurated-link";
            }
            else {
              publication_class = "uncurated-link";
            }

            var pub_str = first_author + title + '<i>' + journal + '</i>' + volume + issue + pages + year
            if(first_author === "No authors listed." &&
               title === "No title." &&
               journal === "No journal." &&
               volume === "No volume." &&
               issue === "No issue." &&
               pages === "No pages." &&
               year === "No year."
              ) {
                console.log("no publication: " + pubmed_id)
            }
            else {
              let article_str = '<a target="_blank" rel="noopener noreferrer" href="' + url + '" class="list-group-item"><h6 class="list-group-item-text ' + publication_class + ' m-0">' + pub_str + '</h6></a>'
              //console.log(article_str)
              let article = {article: article_str}
              self.items.push(article)
            }
          })
          //console.log(self.items)
        }
      },
      getSortOrder() {
        var self = this
        var sort_order = []

        // default sort curated to top
        let tmp_obj = {}
        tmp_obj['Curated'] = "desc"
        sort_order.push(tmp_obj)

        //sort_order["Curated"] = "desc"

        //$('div.list-group.row-sort-order.mx-1.list-group-horizontal-md')

        // get sort order
        //#select-box li[class|="row-sort-order"]
        // ul.dropdown-menu
        var sort_order_children = $("#select-box ul.dropdown-menu").children()
        //console.log(sort_order_children)
        //console.log("sort order children length: " + sort_order_children.length);

        if(sort_order_children) {
          sort_order_children.each(function(index) {
            //
            //console.log("child class: " + $(this).attr('class'));
            //console.log($(this).children().find('.sortflip'))

            var select_item = $(this).children().find('input[type=checkbox]')
            //console.log(select_item)
            //console.log(select_item[0].id)

            //console.log($(this).children().find('author-sort-order'))

            var select_checked = select_item.is(':checked')

            //console.log(select_checked)

            if(!select_checked) {
              return true
            }

            var sortflip = $(this).children().find('.sortflip')
            //console.log(sortflip)

            if(select_item[0].id == 'checkbox-1') {
              //console.log('checkbox-1')
              var select_item_text = select_item.parent().text().trim()
              //console.log("select_item_text: " + select_item_text)

              let direction = self.getSortDirection(sortflip)
              //console.log('direction: ' + direction)
              //sort_order[select_item_text] = direction
              let tmp_obj = {}
              tmp_obj[select_item_text] = direction
              sort_order.push(tmp_obj)
            }
            else if(select_item[0].id == 'checkbox-2') {
              //console.log('checkbox-2')
              var select_item_text = select_item.parent().text().trim()
              //console.log("select_item_text: " + select_item_text)

              let direction = self.getSortDirection(sortflip)
              //console.log('direction: ' + direction)
              //sort_order[select_item_text] = direction
              let tmp_obj = {}
              tmp_obj[select_item_text] = direction
              sort_order.push(tmp_obj)
            }
            else if(select_item[0].id == 'checkbox-3') {
              //console.log('checkbox-3')
              var select_item_text = select_item.parent().text().trim()
              //console.log("select_item_text: " + select_item_text)

              let direction = self.getSortDirection(sortflip)
              //console.log('direction: ' + direction)
              //sort_order[select_item_text] = direction

              let tmp_obj = {}
              tmp_obj[select_item_text] = direction
              sort_order.push(tmp_obj)
            }
            else if(select_item[0].id == 'checkbox-4') {
              //console.log('checkbox-4')
              var select_item_text = select_item.parent().text().trim()
              //console.log("select_item_text: " + select_item_text)

              let direction = self.getSortDirection(sortflip)
              //console.log('direction: ' + direction)
              //sort_order[select_item_text] = direction

              let tmp_obj = {}
              tmp_obj[select_item_text] = direction
              sort_order.push(tmp_obj)
            }
          })
        }

        //$.each(sort_order, function(index, value) {
        //  console.warn("sort_order: " + index + ' : ' + value );
        //})

        return sort_order;
      },
      searchFilter(event) {
        var self = this
        //this.items = []

        //console.log(JSON.parse(JSON.stringify(this.items)))

        //console.log("search filter: " + this.searchInput)
        var search_term = this.searchInput
        //this.sortOrder = sort_order
        //this.sortOrder = this.getSortOrder()
        let sort_order = this.getSortOrder()
        let pubmed_list = JSON.parse(JSON.stringify(this.pubmed))

        //console.log(pubmed_list)
        //console.log(this.items)

        self.getPublications(pubmed_list, sort_order, function(result) {
          if(result != null) {
            //console.log(result)
            self.updateItems(result)
          }
        })
      },
      priorityChange(event, up_or_down) {
        //console.log(event)
        //let id = event.target.id
        let id = event.currentTarget.id
        //console.log("called priorityChange");
        //console.log('id: ' + id);
        var order_button = $("#" + id)
        var selected_row = order_button.closest('.row-sort-order')
        //console.log(selected_row)

        if(up_or_down == 'down') {
          //console.log('down')
          //var row_id = $('#select-box b-list-group[class|="row-sort-order"]').last().attr('id')
          //console.log()
          var last = $('div.list-group.row-sort-order.mx-1.list-group-horizontal-md').last()
          //console.log(last)
          if(!selected_row.is(last)) {
            var next = selected_row.next('.row-sort-order');
            selected_row.detach().insertAfter(next);
          }
        }
        else if(up_or_down == 'up') {
          //console.log('up')
          //var first = $('#select-box b-list-group[class|="row-sort-order"]:first');
          var first = $('div.list-group.row-sort-order.mx-1.list-group-horizontal-md').first()
          if(!selected_row.is(first)) {
            var previous = selected_row.prev('.row-sort-order');
            selected_row.detach().insertBefore(previous);
          }
        }
      },
      formatter: function(data) {
        return '<b>' + data + '</b>';
      },
/*      chunkArray(myArray, chunk_size) {
        var index = 0;
        var arrayLength = myArray.length;
        var tempArray = [];

        for(index = 0; index < arrayLength; index += chunk_size) {
          let myChunk = myArray.slice(index, index+chunk_size);
          tempArray.push(myChunk);
        }

        return tempArray;
      },
*/
      splitPubmedList(pubmed_list, sort_order='') {
        var self = this
        return new Promise(function (resolve, reject) {
          //let count = 0
          let count = pubmed_list.length
/*
          for(const pubmed of pubmed_list) {
            console.log(pubmed)
            count++
          }
*/
          //console.log(pubmed_list)
          self.getPublications(pubmed_list, sort_order, function(result) {
            if(result != null) {
              //console.log(result)
              self.updateItems(result)
            }
          })

          resolve(count)
        })
      },
      async getPubmedList(pubmed_list, sort_order = '') {
        let count = await this.splitPubmedList(pubmed_list, sort_order)
        //console.log("count: " + count)
      },
      async getPublications(pubmed_list, sort_order='', callback) {
        //console.log($(JSON.parse(JSON.stringify(pubmed_list))));
        //console.log(JSON.stringify(sort_order));
        //console.log("pubmed list length: " + pubmed_list.length)
        if(!pubmed_list || pubmed_list.length <= 0) {
          callback(null)
        }
        else {
          var details = {
            //'pubmed_id': pubmed_list,
            'pubmed_id': JSON.stringify(pubmed_list),
            'sort_options': JSON.stringify(sort_order),
            'search_input': JSON.stringify(this.searchInput)
          };

          var formBody = [];
          for(var property in details) {
            var encodedKey = encodeURIComponent(property);
            var encodedValue = encodeURIComponent(details[property]);
            formBody.push(encodedKey + "=" + encodedValue);
          }
          formBody = formBody.join("&");

          const rawResponse = await fetch(window.hostname + '/crew/api/v1/export/pubmed', {
            method: 'POST',
            headers: {
            'Content-Type': 'application/x-www-form-urlencoded;charset=UTF-8'
            },
            body: formBody
          })

          const content = await rawResponse.json();
          //console.log(content);
          callback(content)
        }
      }
    }
  }
</script>

<style lang="css" scoped>
  .drag-panel {
    background-color: olive;
    height: 100%;
    max-height: 100%;

    position: fixed;
    top: 0;
    left: 0;
    right: 0;
  }

  .drag-panel-header {
    position: fixed;
    top: 0;
    left: 0;
    right: 0;
    height: 35px;
    background-color: lightgray;
  }

  .header-title {
    position: fixed;
    top: 5px;
    left: 5px;
    //right: 0;
    height: 235px;
    font-size: 20px;
    font-weight: 500; /* 400 is normal; 700 is bold */
  }

  .drag-panel-content {
    position: fixed;
    top: 35px;
    bottom: 30px;
    left: 0;
    right: 0;
    background-color: whitesmoke;
    border: 1px solid black;
    //overflow: auto;
  }

  .drag-panel-footer {
    position: fixed;
    bottom: 0;
    left: 0;
    right: 0;
    height: 30px;
    background-color: lightgray;
  }

  p {
    //font-size: 12px;
  }

  .publication-panel {
    overflow: auto;
    //background-color: blue;
    position: fixed;
    //top: 200px;
    top: 180px;
    bottom: 80px;
    left: 5px;
    right: 10px;
  }

  .publication-panel-footer {
    position: fixed;
    bottom: 0;
    left: 5px;
    right: 0;
    height: 75px;
    //background-color: lightgray;
  }

  .sort-checkbox {
    width: 125px;
  }

  .pagination {
    white-space: nowrap;
    //overflow-x: auto;
    overflow-x: auto;
    //overflow-y: hidden;
    //height: 200px;
    //width: 100%;
    //padding: 0 5px;
    padding-left: 5px;
    padding-right: 5px;
  }

  /deep/ .table > tbody {
    //background-color: blue;
    //position: fixed;
    //top: 25px;
    //bottom: 30px;
    //left: 0;
    //right: 0;
    //overflow: auto;
  }

  /* ::v-deep/ .table > tbody > tr > td { */
  /deep/ .table > tbody > tr > td {
    //max-width: 300px;
    font-size: 10px;
  }


  /deep/ .table > thead > tr > th {
    font-size: 12px;
  }

  /deep/ .curated-link {
    color:blue;
    text-decoration: none;
    background-color: none;
  }
  /deep/ .curated-link:hover{text-decoration:none}

  /deep/ .uncurated-link {
    color:black;
    text-decoration: none;
    background-color: none;
  }
  /deep/ .uncurated-link:hover{text-decoration:none}
</style>
