<template>
  <div class="drag-panel">
    <div class="drag-panel-content">
      <div class="drag-panel-navbar">

        <b-navbar toggleable="md" type="light" variant="light">
          <b-navbar-brand :to="{ name: 'Home' }">CREW Asthma Conceptual Models</b-navbar-brand>

          <b-navbar-toggle target="nav-collapse"></b-navbar-toggle>
            <b-collapse id="nav-collapse" is-nav>
              <b-navbar-nav class="ml-auto">
<!--
                <b-nav-item-dropdown text="Help" right>
-->
<!--
                  <b-dropdown-item :to="{ name: 'Home' }">Home</b-dropdown-item>
-->
                  <b-dropdown-item :to="{ name: 'Help' }">Help</b-dropdown-item>
<!--
                  <b-dropdown-item :to="{ name: 'Feedback' }">Feedback</b-dropdown-item>
-->
<!--
                </b-nav-item-dropdown>
-->
              </b-navbar-nav>
            </b-collapse>
          <!--</b-navbar-toggle>-->
              <!--
            <b-navbar-toggle target="navbar-toggle-collapse">
-->
<!--
              <template #default="{ expanded }">
                <b-icon v-if="expanded" icon="chevron-bar-up"></b-icon>
                <b-icon v-else icon="chevron-bar-down"></b-icon>
              </template>
-->
<!--
            </b-navbar-toggle>
-->
<!--
            <b-collapse id="navbar-toggle-collapse" is-nav>
              <b-navbar-nav class="ml-auto" right>
                <b-nav-item :to="{ name: 'Home' }" right>Home</b-nav-item>
                <b-nav-item :to="{ name: 'Feedback' }" right>Feedback</b-nav-item>
                <b-nav-item :to="{ name: 'Help' }" right>Help</b-nav-item>
-->
<!--
                <b-nav-item-dropdown text="Help" right>
                  <b-dropdown-item :to="{ name: 'Help' }">Help</b-dropdown-item>
                  <b-dropdown-item :to="{ name: 'Feedback' }">Feedback</b-dropdown-item>
                </b-nav-item-dropdown>
-->
<!--
              </b-navbar-nav>
            </b-collapse>
-->
          </b-navbar>
<!--

        <b-navbar toggleable="sm" type="light" variant="light">
          <b-navbar-brand :to="{ name: 'Home' }">CREW Asthma Conceptual Models</b-navbar-brand>

          <b-navbar-toggle target="nav-collapse"></b-navbar-toggle>
          <b-collapse id="nav-collapse" is-nav>
            <b-navbar-nav>
            </b-navbar-nav>

            <b-navbar-nav class="ml-auto">
              <b-nav-item-dropdown text="Help" right>
                <b-dropdown-item :to="{ name: 'Help' }">Help</b-dropdown-item>
                <b-dropdown-item :to="{ name: 'Feedback' }">Feedback</b-dropdown-item>
              </b-nav-item-dropdown>
            </b-navbar-nav>
          </b-collapse>
        </b-navbar>
-->
        <router-view/>

      </div>
    </div>
  </div>
</template>

<script>
  export default {
    name: 'Navbar',
    components: {
    }
  }
</script>

<style scoped>
  .drag-panel {
    background-color: olive;
    height: 100%;
    max-height: 100%;

    position: fixed;
    top: 0;
    left: 0;
    right: 0;
  }

  .drag-panel-content {
    position: fixed;
    top: 0;
    bottom: 0;
    left: 0;
    right: 0;
    background-color: coral;
    //overflow: auto;
  }

  /*
     navbar
   */
  .drag-panel-navbar >>> .navbar { padding: 0px 16px 0px 16px !important; }
  .drag-panel-navbar >>> .navbar .navbar-brand { font-size: 1.2rem; line-height: 12px; vertical-align: center; padding: 8px 16px 8px 16px;}
/*
  .drag-panel-navbar >>> .navbar-nav { margin-top: 0px; }
*/
  .drag-panel-navbar >>> .navbar-nav > li > a { padding: 0px 10px 0px 10px; line-height: 24px; font-size: 1.2rem; }
  .drag-panel-navbar >>> .navbar-collapse.collapse.show { margin-top: 10px; margin-bottom: 10px; }
  .drag-panel-navbar >>> .navbar-toggler-icon { width: 1.0em !important; height: 1.0em !important; vertical-align: top; }
  .drag-panel-navbar >>> .navbar-toggler.collapsed { position: absolute; top: 0; right: 0; margin-right: 16px; max-height:28px !important; }
  .drag-panel-navbar >>> .navbar-toggler.not-collapsed { position: absolute; top: 0; right: 0; margin-right: 16px; max-height:28px !important; }
  .drag-panel-navbar >>> .dropdown-menu { margin-bottom: 10px; }

</style>
