<template>
  <div>
    <b-input-group prepend="Search" class="mt-2 mb-3 px-0">
      <b-form-input
        v-model="cypherQueryText"
        ref="cypherQueryInput"
        v-on:keyup.enter="querySearch">
      </b-form-input>
      <b-input-group-append>
        <b-button variant="outline-secondary"v-on:click="querySearch" >
          <font-awesome-icon icon="search" />
        </b-button>
        <b-button variant="secondary" v-on:click="resetSearch"><i class='fa fa-undo'></i>
          <font-awesome-icon icon="undo" />
        </b-button>
      </b-input-group-append>
    </b-input-group>

    <div
      v-bind:id="identity"
      v-bind:ref="reference"
      class="graph-view border"
      v-resize @resize="onResize"
      :style="{ height: fullHeight+'px' }">
    </div>

  </div>

</template>

<script>
import $ from 'jquery'
//import cytoscape from 'cytoscape'
import EventBus from './eventbus'
import CytoscapeViewBase from './CytoscapeViewBase.vue'

export default {
  name: 'CytoscapeView',
  extends: CytoscapeViewBase,
  components: {
  },
  created() {
    //console.log('Created CytoscapeView');
  },
  props: {
    // model type for this graph (e.g. model A, model B, or modelAB)
    model: {
      type: String,
      required: true,
      //default: {"model": "model-a"}
      default: {"model": "17q12-21 GxE Interactions"}
    },
    identity: {
      type: String,
      required: true,
      //default: {"identity": ""}
    },
    reference: {
      type: String,
      required: true,
      //default: {"reference": "A"}
    }
  },
  data () {
    return {
      cypherQueryText: '',
      fullHeight: 0,
      isMounted: false,
    }
  },
  computed: {
/*
    windowHeight() {
      return this.getWindowHeight();
    },
    windowWidth() {
      return this.getWindowWidth();
    }
*/
    //window.addEventListener('resize', this.handleResize)
  },
  mounted() {
    var self = this
    //console.log("mounted")
    //this.someFunction();

    if(!this.isMounted) {
      this.initCytoscape()
    }

    EventBus.$on('searchGraph', query => {
      //console.log("searchGraph: " + query)
      //console.log(window.cyOne)
      this.updateGraph(window.cyOne, query)
    })

    EventBus.$on('graphOneHeight', height => {
      this.fullHeight = height
    })

    this.isMounted = true
  },
  //computed: {},
  methods: {
/*
    someFunction: function () {
      // Replace the wanted SomeClassA::someFunction()
      this.defaultSomeFunction()
      // Add custom code here
      //console.log("someFunction from child")
    },
*/
    resetSearch: function(event) {
      //console.log("reset search: " + event)
      //this.updateGraph(window.cyOne, '')
      this.updatePanelResize(window.cyOne)
    },
    querySearch: function(event) {
      //console.log("query search: " + event)
      //console.log(this.$refs.cypherQuery)
      //console.log(event.target.value)

      //console.log(this.cypherQueryText)
      EventBus.$emit('searchGraph', this.cypherQueryText)
      //console.log("querySearch calling... searchGraph")
    },
    updatePanelResize(cy) {
      if(!cy) {
        return
      }

      cy.resize()
      cy.fit()
      cy.center()
    },
    onResize(e) {
      //console.log("resize event", e.detail.width, e.detail.height)
      this.width = e.detail.width
      this.fullHeight = e.detail.height

      // call this.updatePanelResize() here,
      // otherwise graph b does not fit properly
      this.updatePanelResize(window.cyOne)
    },
    async updateGraph(cy, query) {
      //var style = this.get_style()
      var graph = await this.get_graph(query)
      //console.log("after created graph: " + JSON.stringify(graph))

      //var self = this

      if(!cy) {
        console.log("Cannot update graph: cy is not defined")
        return
      }

      //setTimeout( function() {
        //cy.nodes().forEach(function(ele) {
        //  console.log("loop", ele.id(), ele.position());
        //});

        cy.ready(function() {
          cy.minZoom(0.05)
          cy.maxZoom(3)
          cy.zoom(0.5)
          cy.boxSelectionEnabled(false)
          cy.autounselectify(true)

          cy.elements().remove();
          cy.add(graph);
          cy.elements().unselectify();
          cy.style().update();

//        cy.layout(layout).run();

          //var parent_nodes = cy.nodes(':parent');
          //parent_nodes.descendants().layout({
          //  name: 'klay'
          //}).run();

          //console.log(cy.json())

          cy.fit()
          cy.center()
        })

        //console.log(cy)
        //this.cytoscapeGraph = cy
        //console.log(this.cytoscapeGraph)

        window.cyOne = cy

      //}, 100);

      //console.log(window.cy)

      //return
    },
    get_style() {
    }
  },
  beforeDestroy() {
    // Unregister the event listener before destroying this Vue instance
    //window.removeEventListener('resize', this.onResize)

    //window.removeEventListener('resize', this.handleResize)
  }
}

</script>
<!--
<style scoped lang="stylus">
-->
<style scoped>
/*
  .text-right,
  .float-right {
    //border: 1px red solid;
  }
*/
  .noscroll {
    overflow: hidden;
  }

  .graph-view {
    width: 100%;
    //height: 100%;
    //height: 125vh;
    //background-color: green;

    //overflow: hidden;
    //position: relative;

    //display: block;

    //display: flex;
    //flex-direction: column;

    //display: flex;
    //flex-wrap: wrap;

    //display: flex;
    //flex-wrap: wrap;
    //margin: 0;
    //padding: 0;
    //border: 5px solid green;
  }

/*
  .cy-editor {
    background-color: whitesmoke;

    width: 100%;
    height: 100%;
    position: relative;
  }
*/
/*
  #cyto {
    //list-style-type: none;
    border: 1px solid gray;
    //margin: 15px;
    //padding: 5px;
    //width: 200px;
    //text-align: center;

    width: 100%;
    height: 100%;
    //background-color: blue;
    //background-color: coral;

    //margin: 0;
    //padding: 0;

    //position: relative;

    //overflow: hidden;
    //position: relative;

    //display: block;

    //flex-grow: 1;

    //width: 100%;
    //height: 300px;
    //display: block;
    //background-color: blue;

    //position: relative;

    //height: 100%;
    //width: 100%;
    //position: absolute;
    //left: 0;
    //top: 0;
    //float: left;

    //position: fixed;
    //top: 100px;
    //bottom: 100px;
    //left: 10px;
    //right: 10px;

    //background:green;
    //height: 100%;
    //width: 100%;
    //position: absolute;
    //right: 0;
    //top: 0;
  }
*/
/*
  #cyto {
    width: 100%;
    height: 600px;
    display: block;
    border: 1px solid blue;
  }
*/
</style>
