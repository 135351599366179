<script>
import $ from 'jquery'
import EventBus from './eventbus'
import cytoscape from 'cytoscape'
export default {
  data () {
    return {
      //cypherQueryText: '',
      doubleClickDelayMs: 1000,
      previousTapStamp: 0,
      width: 0,
      //fullHeight: document.documentElement.clientHeight,
      fullHeight: 0,
      //cytoscape: {},
      //cytoscapeGraph: {},
      cy: {},
      //isMounted: false,
      //runOnce: false,
      graph_is_drawn: false,
      id: 0,
      //model: "A",
      config: {
        style: [{
          selector: 'node',
          style: {
            //'background-color': '#666',
            "content": 'data(id)',
            //'label': 'data(id)',
            "label": "data(name)",
            "text-valign": "center",
            "text-halign": "center",
            "text-wrap": "wrap",
            "text-max-width": 120,
            "font-family": "sans-serif",
            "font-size": 24,
            "font-weight": "bold",
            "height": "data(width)",
            "width": "data(height)",
            "overlay-padding": "5px",
            "overlay-opacity": 0,
            "z-index": 10,
            //"border-opacity": 0,
            "border-opacity": "1",
            "border-width": 2,
            //"border-width": "1px",
            "border-color": "black",
            //"background-color": "data(bg)",
            //"shape": "roundrectangle"
            "shape": "data(shape)"
            }
        }, {
          selector: 'node[bg]',
          style: {
            "background-color": "data(bg)",
            "background-opacity": "0.45",
            "border-color": "data(bg)",
            "border-opacity": "0.8",
            "border-width": "1px"
          }
        }, {
          selector: 'edge',
          style: {
            //'width': 5,
            //'line-color': '#ccc',
            //'target-arrow-color': '#ccc',
            //'target-arrow-shape': 'triangle'
            "curve-style": 'bezier',
            //"curve-style": 'unbundled-bezier',
            "control-point-step-size": 40,
            "control-point-distance": 30,
            "control-point-weight": 0.7,
            "opacity": 0.9,
            "overlay-padding": "3px",
            "overlay-opacity": 0,
            //"label": "data(type)",
            "label": "data(name)",
            "font-family": "sans-serif",
            "font-size": 24,
            "font-weight": "bold",
            "text-rotation": "autorotate",
            "text-margin-x": "0px",
            "text-margin-y": "-20px",
            "width": 10,
            "target-arrow-shape": "triangle",
            'line-color': 'gray',
            //"line-color": "data(color)",
            'target-arrow-color': 'gray',
            'source-arrow-color': 'gray'
            //"target-arrow-color": "data(color)",
            //"source-arrow-color": "data(color)"
          }
        }, {
          selector: ':parent',
          style: {
            //'background-color': Colors.yellow,
            //'background-opacity': 0.333,
            "label": "data(name)",
            "text-valign": "top",
            "text-halign": "center",
            "text-wrap": "wrap",
            "text-max-width": 120,
            //"font-family": "FreeSet,Arial,sans-serif",
            "font-family": "sans-serif",
            "font-size": 24,
            //"font-size": font_size,
            "font-weight": "bold",
          }
        }],
        layout: {
          name: 'grid',
          rows: 1
        }
      }
    }
  },
  created() {
    //console.log('Created from the parent!');
  },
  methods: {
    someFunction: function () {
     this.defaultSomeFunction();
    },
    // defaultSomeFunction acts like parent.someFunction() so call it in inheritance
    defaultSomeFunction: function () {
      //console.log("defaultSomeFunction parent called");
    },
    async initCytoscape() {
      var self = this
      //self.cy = cytoscape({

      let cy = cytoscape({
        container: this.$refs.cyto,
        elements: this.config.elements,
        style: this.config.style,
        layout: this.config.layout
      })

      //
      // cytoscape events
      //
      cy.on('tap', 'node, edge', function(event) {
        //console.log('tap: ' + event.target.id())
        let target = event.target
        //this.tap_target = target

        //console.log(target)

        if(target.isEdge()) {
/*
          var edge_id = target.data().id
          var edge_source = target.data().source
          var edge_target = target.data().target
          var edge_source_name = get_node(cy_graph, edge_source).data.name
          var edge_target_name = get_node(cy_graph, edge_target).data.name

          var edge_label = edge_source_name + " &#x21E8; " + edge_target_name

          var edge_query = "MATCH (e:Edge)-[r:publication]-(p:Publication) WHERE e.edge_id = \"" + edge_id + "\" RETURN p"

          run_cypher_query(edge_query, function(result) {
            if(result != null) {
              set_publication_list(result, edge_label)
            }
          })
*/
        }
        else if(target.isNode()) {
          //console.log("target is node")

          var node = target.data()
          var node_name = target.data().name
          //console.log("node_name: " + node_name + ", node: " + JSON.stringify(node))

          var node_id = target.data().node_id;
          //var node_label = target.data().label;
          //var node_query = "MATCH (n:Node)-[r:publication]-(p:Publication) WHERE n.node_id = \"" + node_id + "\" RETURN p";

          var node_query = node_id;

          //console.log("node_query: " + node_query);

          var currentTapStamp = event.timeStamp;
          var msFromLastTap = currentTapStamp - this.previousTapStamp;
          if(msFromLastTap < this.doubleClickDelayMs) {
            event.target.trigger('doubleTap', event);
          }
          else {
            self.get_publication(node_query, function(result) {
              if(result != null) {
                //console.log(result)
                //set_publication_list(result, node_name);
                EventBus.$emit('setPublication', {result: result, name: node_name})
              }
            });
          }

          this.previousTapStamp = currentTapStamp;

          self.setNodeProperties(node, node_name)
        }
      }) // end cy.on('tap')

      await this.updateGraph(cy, '')
    },
    node_property_label(type) {
      var NODE_PROPERTY = {"alternative_term":"Alternative Term", "type":"Type", "ontology_link":"Ontology Link", "ontology_link_general":"Ontology Link For More General Concept", "relevant_ages":"Relevant Ages", "term":"Term", "timing":"Timing"}

      if(type.toLowerCase() == "alternative_term") {
        return NODE_PROPERTY.alternative_term;
      }
      else if(type.toLowerCase() == "type") {
        return NODE_PROPERTY.type;
      }
      else if(type.toLowerCase() == "ontology_link") {
        return NODE_PROPERTY.ontology_link;
      }
      else if(type.toLowerCase() == "ontology_link_general") {
        return NODE_PROPERTY.ontology_link_general;
      }
      else if(type.toLowerCase() == "relevant_ages") {
        return NODE_PROPERTY.relevant_ages;
      }
      else if(type.toLowerCase() == "term") {
        return NODE_PROPERTY.term;
      }
      else if(type.toLowerCase() == "timing") {
        return NODE_PROPERTY.timing;
      }
      else {
        return type;
      }
    },
    extractHostname(url) {
      var hostname
      //find & remove protocol (http, ftp, etc.) and get hostname

      if(url.indexOf("//") > -1) {
        hostname = url.split('/')[2]
      }
      else {
        hostname = url.split('/')[0]
      }

      //find & remove port number
      hostname = hostname.split(':')[0]
      //find & remove "?"
      hostname = hostname.split('?')[0]

      return hostname
    },
    async setNodeProperties(node, title) {
      //console.log('setNodeProperties: ' + JSON.stringify(node) + ', ' + title)

      //
/*
    // update the node properties  header with the selected node name
    $('#node-properties-header').html(title);

    // remove all items from node properties
    $('#node-properties-list-group').empty();
 */
      var self = this
      var node_info = '<div class="container-fluid">';
      var skip_list = ['id', 'label', 'radius', 'color', 'width', 'height', 'name', 'node_id', 'term', 'parent', 'alternative_term', 'ontology_link_general', 'relevant_ages', 'bg', 'shape', 'timing'];
      var link_list = ['ontology_link', 'ontology_link_general'];

      $.each(node, function(key, value) {
        if($.inArray(key, skip_list) >= 0) {
          return true; // skip
        }

        //console.log("key: " + key + ", value: " + value);
        var key_label = self.node_property_label(key);
        //var key_label = key
        if(key_label === "Ontology Link") {
          key_label = "Link";
        }

        // debug
        //console.log('key_label: ' + key_label);
        //console.log('value: ' + value);

        node_info += '<div class="row mt-0">';

        node_info += '<div class="border" style="background-color:gainsboro; width:25%; display:inline-block; position:relative; float:left; padding-left: .25em"><h6 class="list-group-item-text m-0">' + key_label + ':</h6>';
        node_info += '</div>';
        node_info += '<div class="border scroll" style="background-color:whitesmoke; width: 75%; display:position:relative; inline-block; float:left; padding-left: .25em">';

        //ontology_link, ontology_link_general
        if($.inArray(key, link_list) >= 0) {
          if((typeof value !== "undefined") && (value !== null)) {
            let hostname = self.extractHostname(value)
            node_info += '<a target="_blank" rel="noopener noreferrer" href="' + value + '">';
            node_info += '<h6 class="list-group-item-text m-0"><font color="black">' + hostname + '</font></h6>';
            node_info += '</a>';
          }
          else {
            // blank row
            node_info += '<h6 class="list-group-item-text m-0">&nbsp;</h6>';
          }
        }
        else {
          if((typeof value !== "undefined") && (value !== null)) {
            node_info += '<h6 class="list-group-item-text m-0"><font color="black">' + value + '</font></h6>';
          }
          else {
            // blank row
            node_info += '<h6 class="list-group-item-text m-0">&nbsp;</h6>';
          }
        }

        node_info += '</div>';
        node_info += '</div>';
      });

      node_info += '</div>';
      //console.log("node_info: " + node_info);

      //$('#node-properties-list-group').append(node_info);

      var data = {title: title, rows: node_info}
      EventBus.$emit('setNodeProperty', data)
    },
    async get_publication(search_query, callback) {
      //console.log("Query: " + search_query)

      // https://bmistage.biostat.wisc.edu/crew/api/v1/export/crew-publication?q=n62

      // publications
      let url = window.hostname + "/crew/api/v1/export/crew-publication?q=" + search_query

      var response = await fetch(url)
      .then(res => res.json())
      .then(data => {
        if(data) {
          //console.log(data)
        }

        return data
      })

      //return response
      callback(response)
    },
    async get_manual_layout_edges() {
      //const response = await fetch('https://bmistage.biostat.wisc.edu/test/crew_export_data.json')
      let url = window.hostname + '/crew/api/v1/public/conf/crew_export_data.json'
      //let url = 'https://localhost/crew/api/v1/public/conf/crew_export_data.json'
      const response = await fetch(url)
      .then(res => res.json())
      .then(res => {
        //console.log("res: " + JSON.stringify(res))

        var edges = {}
        if(!res.elements.edges) {
          return edges
        }

        edges = res.elements.edges.map(edge => {
          //console.log("edge: " + JSON.stringify(edge.data.id));
          //return ([{ id: edge.data.id, source: edge.data.source }])
          //console.log(edge.data.edgeType)
          //console.log(edge.data.name)
          return ({ "classes": edge.classes, "data": { "bg": edge.data.bg, "classification": edge.data.classification, "height": edge.data.height, "id": edge.data.id, "edgeID": edge.data.edgeID, "lineType": "bezier", "source": edge.data.source, "target": edge.data.target, "edgeType": edge.data.edgeType, "name": edge.data.name }, "grabbable": edge.grabbable, "group": edge.group, "locked": edge.locked, "pannable": edge.pannable, "position": { "x": edge.position.x, "y": edge.position.y }, "removed": edge.removed, "selectable": edge.selectable, "selected": edge.selected });
        })

        return edges
      })

      return response
    },
    async get_manual_layout_nodes() {
      //let url = 'https://bmistage.biostat.wisc.edu/crew/api/v1/public/conf/crew_export_data.json'
      //const response = await fetch(url)

      //const response = await fetch('https://bmistage.biostat.wisc.edu/test/crew_export_data.json')

      let url = window.hostname + '/crew/api/v1/public/conf/crew_export_data.json'
      const response = await fetch(url)
      .then(res => res.json())
      .then(res => {
        //console.log("res: " + JSON.stringify(res))

        //console.log("model: " + this.model)
        var nodes = {}
        if(!res.elements.nodes) {
          return nodes
        }

        nodes = res.elements.nodes.map(node => {
          //console.log("node: " + JSON.stringify(node.data.id) + ", " + node.data.model);

          return ({ "classes": node.classes, "data": { "bg": node.data.bg, "classification": node.data.classification, "height": node.data.height, "id": node.data.id, "name": node.data.name, "ontologyLink": node.data.ontologyLink, "nodeID": node.data.nodeID, "nodeType": node.data.nodeType, "parent": node.data.parent, "resize": node.data.resize, "term": node.data.term, "timing": node.data.timing, "type": node.data.type, "width": node.data.width, "model": node.data.model}, "grabbable": node.grabbable, "group": node.group, "locked": node.locked, "pannable": node.pannable, "position": { "x": node.position.x, "y": node.position.y }, "removed": node.removed, "selectable": node.selectable, "selected": node.selected });
        })

        return nodes
      })

      return response
    },
    async search_manual_edges(edges, edge_id, callback) {
      let result = $.grep(edges, function(element, index) {
        return element.data.id === edge_id;
      })

      callback(result)
    },
    async search_manual_nodes(nodes, node_id, callback) {
      let result = $.grep(nodes, function(element, index) {
        return element.data.id === node_id
      })

      callback(result)
    },
    get_node(graph, node_id) {
      var data_found = graph.find(function(ele) {
        if(ele.group === "nodes" && ele.data.id === node_id) {
          return ele
        }
        else {
          return null
        }
      })

      if(data_found) {
        return data_found
      }
      else {
        return null
      }
    },
    node_exists(graph, node_id) {
      var data_found = graph.find(function(ele) {
        if(ele.group === "nodes" && ele.data.node_id === node_id) {
          return true
        }
        else {
          return false
        }
      })

      if(data_found) {
        return true
      }
      else {
        return false
      }
    },
    edge_exists(graph, source, target) {
      var data_found = graph.find(function(ele) {
        if(ele.group === "edges" && ele.data.source === source && ele.data.target === target) {
          return true
        }
        else {
          return false
        }
      })

      if(data_found) {
        return true
      }
      else {
        return false
      }
    },
    async update_graph(graph, manual_edges, manual_nodes) {
      var original_graph = []
      var cy_graph = []
      var self = this

      //console.log(graph)

      if(!graph.nodes.length) {
        console.warn("update_graph(): cannot get nodes length")
        return
      }

      for(var i = 0; i < graph.nodes.length; i++) {
        let d = graph.nodes[i]
        //console.log(JSON.stringify(d))

        if(d.label == "Publication" || d.label == "Edge") {
          continue
        }

        //console.log("this.model: " + this.model)

        if(self.node_exists(cy_graph, d.id)) {
          //console.log("id: " + d.id + ", parent_node: " + d.parent_node);
          continue
        }

        self.search_manual_nodes(manual_nodes, d.node_id, function(manual_node) {
          var node_data = JSON.parse(JSON.stringify(manual_node))
          //console.log(node_data)

          var position_x = node_data.map(function(node) {
            return node.position.x;
          });

          var position_y = node_data.map(function(node) {
            return node.position.y;
          });

          var name = node_data.map(function(node) {
            return node.data.name;
          });

          var term = node_data.map(function(node) {
            return node.data.nodeType;
          });

          var parent_id = node_data.map(function(node) {
            return node.data.parent;
          });

          var nodeType = node_data.map(function(node) {
            return node.data.nodeType;
          });

          var timing = node_data.map(function(node) {
            return node.data.timing;
          });

          var ontologyLink = node_data.map(function(node) {
            return node.data.ontologyLink;
          });

          var width = node_data.map(function(node) {
            return node.data.width;
          });

          var height = node_data.map(function(node) {
            return node.data.height;
          });

          var background_color = node_data.map(function(node) {
            return node.data.bg;
          });

          var shape = node_data.map(function(node) {
            //return node.data.shape;
            return node.data.type;
          });

          var model = node_data.map(function(node) {
            return node.data.model;
          });

          position_x = parseInt(position_x) || 0;
          position_y = parseInt(position_y) || 0;
          name = name.toString() || '';
          term = term.toString() || '';
          parent_id = parent_id.toString() || '';
          nodeType = nodeType.toString() || '';
          timing = timing.toString() || '';
          ontologyLink = ontologyLink.toString() || '';

          width = parseInt(width) || 0;
          height = parseInt(height) || 0;
          background_color = background_color.toString() || '';
          shape = shape.toString() || '';
          model = model.toString() || '';

          //console.log("model: " + model)
          //console.log("self.model: " + self.model)
          if(self.model == model || model == "All") {
            //console.log("models DO NOT match")

            //console.log("d.node_id: " + d.node_id);
            //console.log("d.id: " + d.id);

            cy_graph.push({
              group: "nodes",
              data: {
                //id: d.id,
                id: d.node_id,
                label: d.label,
                //name: d.name,
                name: name,
                //radius: d.radius,
                node_id: d.node_id,
                //term: d.term,
                term: term,
                alternative_term: d.alternative_term,
                //parent: d.parent_node,
                parent: parent_id,
                //type: d.type,
                type: nodeType,
                //ontology_link: d.ontology_link,
                ontology_link: ontologyLink,
                ontology_link_general: d.ontology_link_general,
                relevant_ages: d.relevant_ages,
                //timing: d.timing,
                timing: timing,
                //color: nodeColor(d.type),
                //color: background_color,
                bg: background_color,
                shape: shape,
                //weight: d.weight,
                //height: d.height
                //width: node_dimension.width,
                //height: node_dimension.height
                width: width,
                height: height
              },
              position: {
                x: position_x,
                y: position_y
              }
            });

            original_graph.push({
              group: "nodes",
              data: {
                id: d.id,
                label: d.label,
               name: d.name,
                //radius: d.radius,
                node_id: d.node_id,
                parent: d.parent_node,
                type: d.type
              }
            });
          }
        })
      }

      // not synched
      for(var j = 0; j < graph.links.length; j++) {

        let d = graph.links[j]
        //console.log(JSON.stringify(d))
        //console.log("edge_id: " + JSON.stringify(d.id))
        //console.log("edge name: " + JSON.stringify(d.name))

        if(self.node_exists(original_graph, d.source) && self.node_exists(original_graph, d.target)) {
          //console.log("source and target exists");
          self.search_manual_edges(manual_edges, d.edge_id, function(manual_edge) {
            var edge_data = JSON.parse(JSON.stringify(manual_edge))
            //console.log(edge_data)

            var name = edge_data.map(function(edge) {
              return edge.data.name;
            });

            name = name.toString() || '';
            //console.log("name: " + name)

          cy_graph.push({
            group: "edges",
            data: {
              id: d.id,
              edge_id: d.edge_id,
              source: d.source,
              target: d.target,
              //type: edgeType,
              //name: d.type,
              name: name,
              //color: background_color
            },
            //position: {
            //  x: position_x,
            //  y: position_y
            //}
          })
          })
        }
      }

      return cy_graph;
    },
    async get_graph(search_query) {
      //console.log("Query: " + search_query)

      var manual_edges = await this.get_manual_layout_edges()
      //console.log("manual_edges: " + JSON.stringify(manual_edges))

      var manual_nodes = await this.get_manual_layout_nodes()
      //console.log("manual_nodes: " + JSON.stringify(manual_nodes))

      if(!this.graph_is_drawn) {
        //show_loading_message()

        // get graph data (nodes and edges)
        let url = window.hostname + "/crew/api/v1/export/crew-graph?q=" + search_query
        var response = await fetch(url)
        .then(res => res.json())
        .then(graph => {
          if(graph) {
            //this.json = json;
            //console.log(JSON.stringify(json))
            //console.log(graph)

            if(graph.nodes.length > 0) {
              graph = this.update_graph(graph, manual_edges, manual_nodes)
              //console.log(graph)
              this.directed_is_drawn = true;
              //this.graph_is_drawn = true;
            }
            //hide_loading_message();
          }

          return graph
        })

        return response
      }
    }
  }

}
</script>
