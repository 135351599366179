<template>
  <div class="drag-panel">
    <div class="drag-panel-header">
      <span class="header-title">
        <span class="name">Models&nbsp;</span>
<!--
        <span class="version">&nbsp; <small>(version: {{ this.dataVersion }})</small></span>
-->

<!--
        <span class="" id="loading-message-container" style="float: right; display:inline;">
          <div class="spinner-border text-info mb-1" style="width: 10px; height: 10px; "role="status">
            <span class="sr-only">Searching...</span>
          </div>
          <span class="text-info" style="font-size: small">
            Searching...
          </span>
        </span>
-->
      </span>
    </div>
    <div class="drag-panel-content">

      <b-container fluid class="graph-model">
<!--
        <b-row class="graph-model-header">
          <b-col>
          </b-col>
        </b-row>
-->
        <b-row class="graph-model-workarea" v-resize @resize="onResize">
          <b-col>
            <div>

              <b-tabs content-class="mt-2" small
                active-nav-item-class="font-weight-bold text-secondary"
                nav-item-class="font-weight-bold text-danger">
                <b-tab title="17q12-21 GxE Interactions"
                       title-link-class="mytab"
                       active
                       @click="tabClickGraphA($event)">
                  <CytoscapeView
                    :model="modelA"
                    :identity="cyto1"
                    :reference="reference1"/>
                </b-tab>

                <b-tab title="GSDMB Function"
                       title-link-class="mytab"
                       @click="tabClickGraphB($event)">
                  <CytoscapeViewTwo
                    :model="modelB"
                    :identity="cyto2"
                    :reference="reference2"/>
                </b-tab>

              </b-tabs>
            </div>
          </b-col>
        </b-row>

        <b-row class="graph-model-footer">
          <b-col>
          </b-col>
        </b-row>
      </b-container>

    </div>
    <div class="drag-panel-footer"></div>
  </div>
</template>

<script>
  import $ from 'jquery'
  import CytoscapeView from './CytoscapeView.vue'
  import CytoscapeViewTwo from './CytoscapeViewTwo.vue'
  import EventBus from './eventbus'

  export default {
    name: 'CytoscapeTabPanel',
    components: {
      CytoscapeView,
      CytoscapeViewTwo,
    },
    props: {
      // test
      modelA: {
        type: String,
        default() {
          return "17q12-21 GxE Interactions"
        }
      },
      modelB: {
        type: String,
        default() {
          return "GSDMB Function"
        }
      },
      cyto1: {
        type: String,
        default() {
          return "cyto1"
        }
      },
      cyto2: {
        type: String,
        default() {
          return "cyto2"
        }
      },
      reference1: {
        type: String,
        default() {
          return "cyto"
        }
      },
      reference2: {
        type: String,
        default() {
          return "cyto"
        }
      },
      exampleDefaultObject: {
        type: Object,
        default() {
            return {}
        }
      },
      exampleDefaultArray: {
        type: Array,
        default() {
            return []
        }
      }
    },
    data() {
      return {
        dataVersion: ''
      }
    },
    created() {
      //this.show_loading_message();
      //this.getDataVersion();
    },
    mounted() {
    },
    methods: {
      hide_loading_message() {
        //console.log("hiding loading message");
        var loading_message_container = $('#loading-message-container');
        //pagination_container.children().css('visibility', 'hidden');
        //loading_message_container.children().css('display', 'none');
        loading_message_container.css('display', 'none').delay(800);
      },
      show_loading_message()
      {
        //console.log("showing loading message");
        var loading_message_container = $('#loading-message-container');
        //pagination_container.children().css('visibility', 'visible');
        //loading_message_container.children().css('display', 'block');
        loading_message_container.css('display', 'inline');
      },
      getDataVersion() {
        //return "more test"
        //console.log("hostname: " + window.hostname);
        let url = window.hostname + "/crew/api/v1/export/version"
        var response = fetch(url)
        .then(res => res.json())
        .then(version => {
          if(version) {
            //return version
            this.dataVersion = version
          }
        })
      },
      tabClickGraphA(event) {
        //console.log(event)
      },
      tabClickGraphB(event) {
        //console.log(event)
      },
      onResize(e) {
        //console.log("resize panel event", e.detail.width, e.detail.height)
        EventBus.$emit('graphOneHeight', e.detail.height)
      },
    }
  }
</script>

<style scoped>
  .mytab {
    color: red;
    font-size: 10px;
  }
  .drag-panel {
    background-color: olive;
    height: 100%;
    max-height: 100%;

    position: fixed;
    top: 0;
    left: 0;
    right: 0;
  }

  .drag-panel-header {
    position: fixed;
    top: 0;
    left: 0;
    right: 0;
    height: 35px;
    background-color: lightgray;
  }

  .header-title {
    position: fixed;
    top: 5px;
    left: 5px;
    //right: 0;
    height: 35px;
    font-size: 20px;
    font-weight: 500; /* 400 is normal; 700 is bold */
  }

/*
  .header-title .name { float:left }
  .header-title .version { float:right }
*/
  span + span {
    margin-left: 5px;
  }

  .drag-panel-content {
    position: fixed;
    top: 35px;
    bottom: 30px;
    left: 0;
    right: 0;
    background-color: lightblue;
    border: 1px solid black;
    //overflow: auto;
    //-webkit-overflow-scrolling: touch;
  }

  .drag-panel-footer {
    position: fixed;
    bottom: 0;
    left: 0;
    right: 0;
    height: 30px;
    background-color: lightgray;
  }

  p {
    font-size: 12px;
  }

  .graph-model {
    width: 100%;
    height: 100%;
    background-color: whitesmoke;
  }
  .graph-model-header {
    height: 60px;
  }
  .graph-model-workarea {
    //height: 75%;
    position: fixed;
    //top: 80px;
    top: 45px;
    bottom: 150px;
    left: 0;
    right: 0;
    margin-left: 2px;
    margin-right: 2px;
    //background-color: lightblue;
    //border: 1px solid black;
  }
  .graph-model-footer {
    //height: 100px;
    position: fixed;
    bottom: 0;
    left: 0;
    right: 0;
    height: 25px;
  }
</style>
