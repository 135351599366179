import Vue from 'vue'
import VueRouter from 'vue-router'
import Home from '../views/Home.vue'
//import About from '../views/About.vue'
//import Contact from '../views/Contact.vue'

Vue.use(VueRouter)

const routes = [
  {
    path: '/',
    name: 'Home',
    component: Home
  },
  {
    path: '/about',
    name: 'About',
    // route level code-splitting
    // this generates a separate chunk (about.[hash].js) for this route
    // which is lazy-loaded when the route is visited.
    component: () => import(/* webpackChunkName: "about" */ '../views/About.vue')
  },
  {
    path: '/contact',
    name: 'Contact',
    component: () => import(/* webpackChunkName: "contact" */ '../views/Contact.vue')
  },
  {
    path: '/feedback',
    name: 'Feedback',
    component: () => import(/* webpackChunkName: "feedback" */ '../views/Feedback.vue')
  },
//  {
//    path: '/documentation',
//    name: 'Documentation',
//    component: () => import(/* webpackChunkName: "documentation" */ '../views/Documentation.vue')
//  },
  {
    path: '/help',
    name: 'Help',
    component: () => import(/* webpackChunkName: "documentation" */ '../views/Help.vue')
    //component: () => import(/* webpackChunkName: "documentation" */ '../components/HelpPanel.vue')
  },
/*
  {
    path: '/',
    name: '',
    component: () => import('../views/.vue')
  },
*/
]

const router = new VueRouter({
  mode: 'history',
  base: process.env.BASE_URL,
  routes
})

export default router
