/**
 *
 */
import Vue from 'vue'
import App from './App.vue'
import BootstrapVue from 'bootstrap-vue'
//import VueRouter from 'vue-router'
//import router from './router'
import router from './router'

import '../public/main.css'

//import { library } from '@fortawesome/fontawesome-svg-core'
//import { fab } from '@fortawesome/free-brands-svg-icons'
//import { fas } from '@fortawesome/free-solid-svg-icons'
//import { far } from '@fortawesome/free-regular-svg-icons'
//import { FontAwesomeIcon } from '@fortawesome/vue-fontawesome'
//
//library.add(fab, fas, far);
//
//Vue.component('font-awesome-icon', FontAwesomeIcon);

import { library } from '@fortawesome/fontawesome-svg-core'
import { faUserSecret, faEye, faPlusSquare, faMinusSquare, faArrowUp, faArrowDown, faAngleUp, faAngleDown, faSearch, faFolderOpen, faUndo} from '@fortawesome/free-solid-svg-icons'
import { FontAwesomeIcon } from '@fortawesome/vue-fontawesome'

library.add(faArrowUp)
library.add(faArrowDown)
library.add(faAngleUp)
library.add(faAngleDown)
library.add(faPlusSquare)
library.add(faMinusSquare)
library.add(faSearch)
library.add(faFolderOpen)
library.add(faUndo)

Vue.component('font-awesome-icon', FontAwesomeIcon)

import 'bootstrap/dist/css/bootstrap.css'
import 'bootstrap-vue/dist/bootstrap-vue.css'

Vue.use(BootstrapVue)
//Vue.use(VueRouter)

import cytoscape from 'cytoscape'
import klay from 'cytoscape-klay'
cytoscape.use(klay)

import dagre from 'cytoscape-dagre'
cytoscape.use(dagre)

//import elk from 'cytoscape-elk'
//cytoscape.use(elk)

import VueCytoscape from 'vue-cytoscape'
Vue.use(VueCytoscape)

import resize from "vue-element-resize-detector"
Vue.use(resize)

Vue.config.productionTip = false;

//window.hostname = (Vue.config.productionTip) ? 'https://crew.biostat.wisc.edu' : 'https://bmistage.biostat.wisc.edu';
window.hostname = 'https://crew.biostat.wisc.edu'

new Vue({
  router,
  cytoscape,
 //  el: '#app',
 //  router,
 //  components: { App },
 //  template: '<App/>',
 render: h => h(App)
}).$mount('#app')
