<template>
  <div id="app" class="wrapper">
<!--
  <div id="app" class="container">
-->
<!--
    <img alt="Vue logo" src="./assets/logo.png">
    <HelloWorld msg="Welcome to Your Vue.js App"/>
-->
<!--
  <p>this is a test</p>
-->

<!--
  <CytoscapeView id="cytoscape-view" />
-->

<!--
    <CytoscapeView />
-->


        <grid-layout :layout.sync="layout"
                     :col-num="10"
                     :row-height="30"
                     :is-draggable="true"
                     :is-resizable="true"
                     :vertical-compact="true"
                     :use-css-transforms="true"
        >
            <grid-item v-for="item in layout"
                       :static="item.static"
                       :x="item.x"
                       :y="item.y"
                       :w="item.w"
                       :h="item.h"
                       :i="item.i"
                       :minW="item.minW"
                       :minH="item.minH"
                       drag-allow-from=".vue-draggable-handle"
                       drag-ignore-from=".no-drag"
            >
                <div class="text">
                    <div v-if="!item.static" class="vue-draggable-handle"></div>
                    <div class="no-drag">
                      <template>
                        <component v-if="item.isComponent" :is="item.c"></component>
                        <div v-else v-html="item.c"></div>
                      </template>

<!--
                      <span class="text">{{itemTitle(item)}}</span>
-->
                    </div>
<!--
                    <span v-show="item.resize" class="vue-resizable-handle">{{item.resize}}</span>
-->
              </div>
            </grid-item>
        </grid-layout>
  </div>
</template>

<script>
//  import HelloWorld from './components/HelloWorld.vue'
//  import CytoscapeView from './components/CytoscapeView.vue'
  //import cy-editor from './components/CytoscapeView.vue'
  //CytoscapeView: () => import('./components/CytoscapeView.vue')


  import VueGridLayout from 'vue-grid-layout';
  //import CytoscapePanel from '../components/CytoscapePanel.vue'
  //import CytoscapePanelTwo from '../components/CytoscapePanelTwo.vue'
  import CytoscapeTabPanel from '../components/CytoscapeTabPanel.vue'
  import Navbar from '../components/Navbar.vue'
  //import Test from '../components/Test.vue'
  import PublicationPanel from '../components/PublicationPanel.vue'
  import NodePropertyPanel from '../components/NodePropertyPanel.vue'

  export default {
    name: 'App',
    components: {
      GridLayout: VueGridLayout.GridLayout,
      GridItem: VueGridLayout.GridItem,
      //CytoscapePanelOne: CytoscapePanel,
      //CytoscapePanelTwo: CytoscapePanelTwo,
      CytoscapeTabPanel: CytoscapeTabPanel,
      PublicationPanel: PublicationPanel,
      NodePropertyPanel: NodePropertyPanel,
      Navbar,
      //Test
    },
    data() {
      return {
        layout: [
          //
          // navbar must be listed last (top to bottom) in the layout below
          // to be on top of the other panels
          //
          //{"x":9,"y":2,"w":3,"h":15,"i":"0", static: false, resize: false,"c": 'Test', isComponent: true},
          //{"x":2,"y":0,"w":2,"h":4,"i":"1", static: true},
          //{"x":0,"y":2,"w":4,"h":20,"i":"1", minW:4, minH:20, static: false, resize: true, "c": 'CytoscapePanelOne', isComponent: true},
          //{"x":4,"y":2,"w":4,"h":20,"i":"2", minW:4, minH:20, static: false, resize: true, "c": 'CytoscapePanelTwo', isComponent: true},
          {"x":0,"y":2,"w":7,"h":20,"i":"1", minW:5, minH:15, static: false, resize: true, "c": 'CytoscapeTabPanel', isComponent: true},
          {"x":7,"y":2,"w":3,"h":15,"i":"3", minW:3, minH:15, static: false, resize: true, "c": 'PublicationPanel', isComponent: true},
          {"x":7,"y":15,"w":3,"h":5,"i":"4", minW:3, minH:5, static: false, resize: true, "c":  'NodePropertyPanel', isComponent: true},
          {"x":0,"y":0,"w":10,"h":1,"i":"100", minW:10, minH:1, static: true, resize: false, "c": 'Navbar', isComponent: true},

            //{"x":6,"y":0,"w":2,"h":3,"i":"3", static: false},
                //{"x":8,"y":0,"w":2,"h":3,"i":"4", static: false},
                //{"x":10,"y":0,"w":2,"h":3,"i":"5", static: false},
                //{"x":0,"y":5,"w":2,"h":5,"i":"6", static: false},
                //{"x":2,"y":5,"w":2,"h":5,"i":"7", static: false},
                //{"x":4,"y":5,"w":2,"h":5,"i":"8", static: false},
                //{"x":6,"y":3,"w":2,"h":4,"i":"9", static: true},
                //{"x":8,"y":4,"w":2,"h":4,"i":"10", static: false},
                //{"x":10,"y":4,"w":2,"h":4,"i":"11", static: false},
                //{"x":0,"y":10,"w":2,"h":5,"i":"12", static: false},
                //{"x":2,"y":10,"w":2,"h":5,"i":"13", static: false},
                //{"x":4,"y":8,"w":2,"h":4,"i":"14", static: false},
                //{"x":6,"y":8,"w":2,"h":4,"i":"15", static: false},
                //{"x":8,"y":10,"w":2,"h":5,"i":"16", static: false},
                //{"x":10,"y":4,"w":2,"h":2,"i":"17", static: false},
                //{"x":0,"y":9,"w":2,"h":3,"i":"18", static: false},
                //{"x":2,"y":6,"w":2,"h":2,"i":"19", static: false}
            ],
            draggable: true,
            resizable: true,
            index: 0,
            eventLog: []
        }
    },
    watch: {
        eventLog: function() {
            const eventsDiv = this.$refs.eventsDiv;
            eventsDiv.scrollTop = eventsDiv.scrollHeight;
        }
    },
    methods: {
        itemTitle(item) {
            let result = item.i;
            if (item.static) {
                result += " - Static";
            }
            return result;
        },
        moveEvent: function(i, newX, newY){
            const msg = "MOVE i=" + i + ", X=" + newX + ", Y=" + newY;
            this.eventLog.push(msg);
            console.log(msg);
        },
        movedEvent: function(i, newX, newY){
            const msg = "MOVED i=" + i + ", X=" + newX + ", Y=" + newY;
            this.eventLog.push(msg);
            console.log(msg);
        },
        resizeEvent: function(i, newH, newW, newHPx, newWPx){
            const msg = "RESIZE i=" + i + ", H=" + newH + ", W=" + newW + ", H(px)=" + newHPx + ", W(px)=" + newWPx;
            this.eventLog.push(msg);
            console.log(msg);
        },
        resizedEvent: function(i, newX, newY, newHPx, newWPx){
            const msg = "RESIZED i=" + i + ", X=" + newX + ", Y=" + newY + ", H(px)=" + newHPx + ", W(px)=" + newWPx;
            this.eventLog.push(msg);
            console.log(msg);
        },
        containerResizedEvent: function(i, newH, newW, newHPx, newWPx){
            const msg = "CONTAINER RESIZED i=" + i + ", H=" + newH + ", W=" + newW + ", H(px)=" + newHPx + ", W(px)=" + newWPx;
            this.eventLog.push(msg);
            console.log(msg);
        },
        layoutCreatedEvent: function(newLayout){
            this.eventLog.push("Created layout");
            console.log("Created layout: ", newLayout)
        },
        layoutBeforeMountEvent: function(newLayout){
            this.eventLog.push("beforeMount layout");
            console.log("beforeMount layout: ", newLayout)
        },
        layoutMountedEvent: function(newLayout){
            this.eventLog.push("Mounted layout");
            console.log("Mounted layout: ", newLayout)
        },
        layoutReadyEvent: function(newLayout){
            this.eventLog.push("Ready layout");
            console.log("Ready layout: ", newLayout)
        },
        layoutUpdatedEvent: function(newLayout){
            this.eventLog.push("Updated layout");
            console.log("Updated layout: ", newLayout)
        },
    }
  }
</script>

<style scoped>
/*
  prevent publications from trailing
  with window scroll (Safari only)
*/
  .wrapper {
    width: 100vw;
    height: 100vh;
    overflow-y: scroll;
    -webkit-overflow-scrolling: touch;
  }

.vue-grid-layout {
    background: #eee;
}
#app >>> .vue-resizable-handle {
    //background-color: coral;
    position: absolute;
    width: 30px;
    height: 30px;
    bottom: 0;
    right: 0;
    //background: none;
    background-position: 100% 100%;
    padding: 0 3px 3px 0;
    background-repeat: no-repeat;
    background-origin: content-box;
    -webkit-box-sizing: border-box;
    box-sizing: border-box;
    cursor: se-resize;
}
.vue-grid-item:not(.vue-grid-placeholder) {
    background: #ccc;
    border: 1px solid black;
}

.vue-grid-item .resizing {
    opacity: 0.9;
}
.vue-grid-item .static {
    background: #cce;
}
.vue-grid-item .text {
    font-size: 24px;
    //text-align: center;
    position: absolute;
    top: 0;
    bottom: 0;
    left: 0;
    right: 0;
    margin: auto;
    height: 100%;
    width: 100%;
}
.vue-grid-item .no-drag {
    height: 100%;
    width: 100%;
}
.vue-grid-item .minMax {
    font-size: 12px;
}
.vue-grid-item .add {
    cursor: pointer;
}
.vue-draggable-handle {
    position: absolute;
    //width: 20px;
    width: 100%;
    height: 35px;
    top: 0;
    left: 0;
    //background-color: coral;
/*
    //background: url("data:image/svg+xml;utf8,<svg xmlns='http://www.w3.org/2000/svg' width='10' height='10'><circle cx='5' cy='5' r='5' fill='#999999'/></svg>") no-repeat;
    background-position: bottom right;
    //background-repeat: no-repeat;
    //background-origin: content-box;
*/
    //padding: 0 8px 8px 0;
    box-sizing: border-box;
    cursor: move;
    z-index: 9999;
}
/*
.vue-grid-item .vue-resizable-handle {
.wrapper .vue-resizable-handle {
.vue-grid-item>.vue-resizable-handle {
 */
/*
.vue-grid-item .vue-resizable-handle {
  background-color: coral;
  position: absolute;
    width: 30px;
    height: 30px;
    bottom: 0;
    right: 0;
    background: url(data:image/svg+xml;base64,PHN2ZyBzdHlsZT0iYmFja2dyb3VuZC1jb2xvcjojZmZmZmZmMDAiIHhtbG5zPSJodHRwOi8vd3d3LnczLm9yZy8yMDAwL3N2ZyIgd2lkdGg9IjYiIGhlaWdodD0iNiI+PHBhdGggZD0iTTYgNkgwVjQuMmg0LjJWMEg2djZ6IiBvcGFjaXR5PSIuMzAyIi8+PC9zdmc+);
    background-position: 100% 100%;
    padding: 0 3px 3px 0;
    background-repeat: no-repeat;
    background-origin: content-box;
    -webkit-box-sizing: border-box;
    box-sizing: border-box;
    cursor: se-resize;
}
*/

</style>
